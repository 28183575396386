import React from "react"
import PropTypes from "prop-types"

const SilverText = (props) => (
  <p className="text-gray-500" {...props}>
    {props.children}
  </p>
)

SilverText.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

export default SilverText
