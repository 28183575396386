import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import SilverText from "../components/SilverText"
import TechTag from "../components/TechTag"
import ArrowDown from "../components/ArrowDown"
import { HTMLContent } from "../components/Content"

import DesignCirclePartImage from "../images/mask@3x.png"
import Food1Image from "../images/food-1.svg"
import Food2Image from "../images/food-2.svg"
import Food3Image from "../images/food-3.svg"
import Food4Image from "../images/food-4.svg"
import RoboOvalImage from "../images/robo-oval.svg"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"
import ErafImage from "../images/eraf_lv.svg"

const CaseStudyTemplate = ({
  content,
  title,
  mainDescription,
  listImage,
  challengeTitle,
  challengeDescription,
  challengeImage,
  challengeImage2,
  challengeImage3,
  challengeItems,
  solutionTitle,
  solutionDescription,
  solutionImage,
  resultTitle,
  resultDescription,
  resultImage,
  resultSubtitle,
  resultItems,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK */}
      <div className="relative lg:flex container mx-auto py-12 pb-4 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180"
          style={{ left: "35%", top: "5px" }}
        />
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-48"
          style={{ right: "6rem", bottom: "-3rem" }}
        />
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="mx-auto w-2/3"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div className="bg-gray-300 overflow-hidden">
        <div className="relative container mx-auto py-24 px-10 lg:px-32">
          <h2 className="font-semibold text-3xl mb-8 lg:w-2/3">
            {challengeTitle}
          </h2>
          <div className="lg:w-2/3">
            <SilverText>{challengeDescription}</SilverText>
          </div>

          <div className="relative flex flex-row mt-16">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: "-5rem",
                width: "390px",
                height: "390px",
                backgroundColor: "#e7e7e7",
                borderRadius: "500px",
              }}
            ></div>
            <img
              src={RoboOvalImage}
              alt=""
              className="absolute -right-24 -top-24"
            />
            <img
              src={challengeImage.childImageSharp.fixed.src}
              alt=""
              className="relative w-56 mt-16 mr-8 self-start"
            />
            <img
              src={challengeImage2.childImageSharp.fixed.src}
              alt=""
              className="relative w-56 flex mr-8 self-start"
            />
            <img
              src={challengeImage3.childImageSharp.fixed.src}
              alt=""
              className="relative w-56 mt-24 self-start"
            />
          </div>
          <div
            className="absolute flex flex-col bg-gray-900 text-white p-10 pt-0"
            style={{
              bottom: 0,
              right: "7rem",
              width: "14rem",
              height: "40rem",
            }}
          >
            {challengeItems.map((item, i) => {
              const [, pre, highlight, post] = item.match(
                /([^*]*)(?:\*([^*]+)\*)?(.*)/
              )
              return (
                <React.Fragment key={i}>
                  <ArrowDown className="mb-8" />
                  <div className="mb-8">
                    {pre && <p>{pre}</p>}
                    {highlight && <b>{highlight}</b>}
                    <p>{post}</p>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
          <img
            src={DesignCirclePartImage}
            alt=""
            className="absolute w-32 transform rotate-180"
            style={{ left: "0", bottom: "-4rem" }}
          />
        </div>
      </div>

      {/* SOLUTION BLOCK */}
      <div className="container mx-auto pt-32 pb-24 px-10 lg:px-32 lg:flex">
        <div className="relative flex-1 px-12">
          <div
            style={{
              width: "890px",
              height: "890px",
              border: "solid 1px #e2e2e2",
              borderRadius: "1000px",
              position: "absolute",
              right: "-2rem",
              top: "15rem",
            }}
          ></div>
          <img
            src={solutionImage.childImageSharp.fixed.src}
            alt=""
            className="relative"
          />
        </div>
        <div className="relative flex-1 pl-4 pt-8">
          <h2 className="font-semibold text-3xl mb-8">{solutionTitle}</h2>
          <SilverText>{solutionDescription}</SilverText>
        </div>
      </div>

      {/* RESULT BLOCK */}
      <div className="container mx-auto pt-24 pb-32 px-10 lg:px-32 lg:flex">
        <div className="flex-1">
          <h2 className="font-semibold text-3xl mb-8">{resultTitle}</h2>
          <SilverText>{resultDescription}</SilverText>
        </div>
        <div className="relative flex-1">
          <div
            style={{
              width: "305px",
              height: "305px",
              opacity: "0.3",
              border: "solid 25px #3aaf8e",
              borderRadius: "500px",
              position: "absolute",
              right: "0",
              top: "-3rem",
            }}
          ></div>
          <img
            src={resultImage.childImageSharp.fixed.src}
            alt=""
            className="relative w-2/3 mx-auto mt-10 lg:mt-0"
          />
        </div>
      </div>

      {/* VIDEO BLOCK */}
      <div className="relative container mx-auto px-10 lg:px-32 pb-24 flex">
        <iframe
          title="YouTube video"
          width="100%"
          height="640"
          src="https://www.youtube.com/embed/H6Ya5XfypI4"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      {/* THE SYSTEM CAN COOK BLOCK */}
      <div className="" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="container mx-auto py-24 px-10 lg:px-32 md:flex flex-col">
          <h2 className="font-semibold text-3xl mb-8 lg:w-2/3">
            {resultSubtitle}
          </h2>
          <div className="md:flex flex-row justify-between mt-20">
            <div className="flex flex-1 flex-col content-center text-center md:border-r border-gray-500">
              <img
                src={Food1Image}
                alt=""
                className="relative w-15 self-center"
              />
              <h4 className="mt-6">{resultItems[0]}</h4>
            </div>
            <div className="flex flex-1 flex-col content-center text-center md:border-r border-gray-500">
              <img
                src={Food2Image}
                alt=""
                className="relative w-15 self-center"
              />
              <h4 className="mt-4">{resultItems[1]}</h4>
            </div>
            <div className="flex flex-1 flex-col content-center text-center md:border-r border-gray-500">
              <img
                src={Food3Image}
                alt=""
                className="relative w-15 self-center"
              />
              <h4 className="mt-6">{resultItems[2]}</h4>
            </div>
            <div className="flex flex-1 flex-col content-center text-center">
              <img
                src={Food4Image}
                alt=""
                className="relative w-15 self-center"
              />
              <h4 className="mt-6">{resultItems[3]}</h4>
            </div>
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 lg:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="lg:flex lg:flex-row justify-center">
          <AppDevThumbnail className="lg:w-1/3" />
          <SoftDevThumbnail className="lg:w-1/3" />
          <UiUxThumbnail className="lg:w-1/3" />
        </div>
      </div>

      {content && (
        <div className="container mx-auto pt-0 pb-32 px-10 lg:px-32 text-gray-500 font-light text-sm">
          <img src={ErafImage} alt="" className="w-64 mb-10" />
          <HTMLContent className="eraf-content" content={content} />
        </div>
      )}
    </div>
  )
}

const RoboeatzCaseStudy = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{`${project.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${project.frontmatter.mainDescription}`}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default RoboeatzCaseStudy

export const pageQuery = graphql`
  query roboProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitle
        challengeDescription
        challengeImage {
          childImageSharp {
            fixed(width: 411, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeImage2 {
          childImageSharp {
            fixed(width: 411, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeImage3 {
          childImageSharp {
            fixed(width: 411, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeItems
        solutionTitle
        solutionDescription
        solutionImage {
          childImageSharp {
            fixed(width: 960, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultTitle
        resultDescription
        resultImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultSubtitle
        resultItems
      }
    }
  }
`
