import React from "react"

const ArrowDown = (props) => (
  <div
    style={{
      width: 0,
      height: 0,
      borderLeft: "14px solid transparent",
      borderRight: "14px solid transparent",
      borderTop: "14px solid #1eb499",
    }}
    {...props}
  ></div>
)

export default ArrowDown
